<template>
  <div class="organizationSelect" v-show="messagesAvailable">
    <multiselect v-model="selected" :options="options" :options-limit="50" @input="onInput" @close="onClose"
                 @search-change="findOrganizations" track-by="key" label="label" :multiple="isMultiple"
                 :close-on-select="isCloseOnSelect" :internal-search="false" :loading="isLoading"
                 :clear-on-select="isClearOnSelect" :preserve-search="false" :preselect-first="false"
                 :placeholder="$t('comp.organizationselect.organization.placeholder')" :selectLabel="$t('select.selectLabel')"
                 :selectedLabel="$t('select.selectedLabel')" :deselectLabel="$t('select.deselectLabel')"
                 :blockKeys="['Delete']" :disabled="isDisabled">
      <template slot="noOptions">{{ $t('select.noOptions') }}</template>
      <template slot="noResult">{{ $t('select.noResults') }}</template>
    </multiselect>
  </div>

</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Multiselect from 'vue-multiselect'
import OrganizationService from '@/services/organization.service'

export default {
  name: 'OrganizationSelect',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Multiselect
  },
  props: {
    selectedOrganization: {
      type: Object,
      default: null
    },
    selectedOrganizations: {
      type: Array,
      default: null
    },
    view: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      query: '',
      options: [],
      selected: false,
      isLoading: false,
      isMultiple: false
    }
  },
  computed: {
    isCloseOnSelect () {
      return !this.isMultiple
    },
    isClearOnSelect () {
      return this.isMultiple
    }
  },
  watch: {
    selectedOrganization: function () {
      this.preProcessSelectedOrganization()
    },
    selectedOrganizations: function () {
      this.preProcessSelectedOrganizations()
    }
  },
  beforeMount () {
    this.setIsMultiple()
    this.isMultiple ? this.preProcessSelectedOrganizations() : this.preProcessSelectedOrganization()
  },
  methods: {
    setIsMultiple () {
      if (this.selectedOrganizations) { this.isMultiple = true }
    },
    findOrganizations (query) {
      if (query && query.length >= 3) {
        this.isLoading = true
        const organizationService = new OrganizationService(this.view)
        organizationService.select({ filter: query }).then((organizations) => {
          this.options = organizations // sorted in bacakend
          this.isLoading = false
        }).catch(error => {
          this.options = []
          this.isLoading = false
          console.error(error)
        })
      }
    },
    reset () {
      this.query = ''
      this.options = []
    },
    onClose () {
      this.reset()
    },
    onInput (value) {
      if (this.isMultiple) {
        this.$emit('update:selectedOrganizations', value)
      } else {
        this.$emit('update:selectedOrganization', value)
      }
      this.reset()
    },
    preProcessSelectedOrganization () {
      this.selected = this.selectedOrganization ? this.selectedOrganization : null
    },
    preProcessSelectedOrganizations () {
      this.selected = this.selectedOrganizations ? this.selectedOrganizations : []
    }
  }
}
</script>
