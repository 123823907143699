<template>
  <div class="resourceSelect" v-show="messagesAvailable">
    <multiselect v-model="selected" :options="options" @input="onInput" track-by="key" label="label"
                 :multiple="isMultiple" :close-on-select="isCloseOnSelect" :internal-search="true"
                 :clear-on-select="isClearOnSelect" :preserve-search="false" :preselect-first="false"
                 :placeholder="$t('comp.resourceselect.resource.placeholder')" :selectLabel="$t('select.selectLabel')"
                 :selectedLabel="$t('select.selectedLabel')" :deselectLabel="$t('select.deselectLabel')"
                 :blockKeys="['Delete']" :disabled="isDisabled" >
    </multiselect>
  </div>

</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Multiselect from 'vue-multiselect'
import ResourceService from '@/services/resource.service'

export default {
  name: 'ResourceSelect',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Multiselect
  },
  props: {
    selectedResource: {
      type: Object,
      default: null
    },
    selectedResources: {
      type: Array,
      default: null
    },
    view: {
      type: String,
      required: true
    },
    resourceType: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: [],
      selected: false,
      isMultiple: false
    }
  },
  beforeMount () {
    this.setIsMultiple()
    this.loadResourceOptions()
    this.isMultiple ? this.preProcessSelectedResources() : this.preProcessSelectedResource()
  },
  computed: {
    isCloseOnSelect () {
      return !this.isMultiple
    },
    isClearOnSelect () {
      return this.isMultiple
    }
  },
  watch: {
    selectedResource: function () {
      this.preProcessSelectedResource()
    },
    selectedResources: function () {
      this.preProcessSelectedResources()
    }
  },
  methods: {
    setIsMultiple () {
      if (this.selectedResources) { this.isMultiple = true }
    },
    loadResourceOptions () {
      const resourceService = new ResourceService(this.view)
      resourceService.select({ resourceType: this.resourceType }).then((resources) => {
        this.options = resources // Sorted in backend
      }).catch(error => {
        this.options = []
        console.error(error)
      })
    },
    reset () {
      this.options = []
    },
    onInput (value) {
      if (this.isMultiple) {
        this.$emit('update:selectedResources', value)
      } else {
        this.$emit('update:selectedResource', value)
      }
    },
    preProcessSelectedResource () {
      this.selected = this.selectedResource ? this.selectedResource : null
    },
    preProcessSelectedResources () {
      this.selected = this.selectedResources ? this.selectedResources : []
    }
  }
}
</script>
