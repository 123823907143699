<template>
  <div class="unixGroupSelect" v-show="messagesAvailable">
    <multiselect v-model="selected" :options="options" @input="onInput" track-by="key" label="label"
                 :multiple="isMultiple" :close-on-select="isCloseOnSelect" :internal-search="true"
                 :clear-on-select="isClearOnSelect" :preserve-search="false" :preselect-first="false"
                 :placeholder="placeholder" :selectLabel="$t('select.selectLabel')"
                 :selectedLabel="$t('select.selectedLabel')" :deselectLabel="$t('select.deselectLabel')"
                 :blockKeys="['Delete']" :custom-label="selectLabel">
    </multiselect>
  </div>

</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Multiselect from 'vue-multiselect'
import ProjectService from '@/services/project.service'

export default {
  name: 'UnixGroupSelect',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Multiselect
  },
  props: {
    selectedUnixgroup: {
      type: Object,
      default: null
    },
    selectedUnixgroups: {
      type: Array,
      default: null
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isMultiple: false,
      options: [],
      selected: null
    }
  },
  beforeMount () {
    this.setIsMultiple()
    this.loadUnixGroupOptions()
    this.isMultiple ? this.preProcessSelectedUnixGroups() : this.preProcessSelectedUnixGroup()
  },
  computed: {
    isCloseOnSelect () {
      return !this.isMultiple
    },
    isClearOnSelect () {
      return this.isMultiple
    },
    placeholder () {
      if (this.isMultiple) {
        return this.$i18n.t('comp.unixgroupselect.unixgroups.placeholder')
      } else {
        return this.$i18n.t('comp.unixgroupselect.unixgroup.placeholder')
      }
    }
  },
  watch: {
    selectedUnixgroup: function () {
      this.preProcessSelectedUnixGroup()
    },
    selectedUnixgroups: function () {
      this.preProcessSelectedUnixGroups()
    }
  },
  methods: {
    setIsMultiple () {
      if (this.selectedUnixgroups) { this.isMultiple = true }
    },
    loadUnixGroupOptions () {
      const projectService = new ProjectService(this.view)
      projectService.selectUnixGroups().then((unixGroups) => {
        this.options = unixGroups // Sorted in backend
      }).catch(error => {
        console.error(error)
      })
    },
    reset () {
      this.options = []
    },
    onClose () {
      this.reset()
    },
    preProcessSelectedUnixGroup () {
      this.selected = this.selectedUnixgroup ? this.selectedUnixgroup : null
    },
    preProcessSelectedUnixGroups () {
      this.selected = this.selectedUnixgroups ? this.selectedUnixgroups : []
    },
    onInput (value) {
      if (this.isMultiple) {
        this.$emit('update:selectedUnixgroups', value)
      } else {
        // console.log(value)
        this.$emit('update:selectedUnixgroup', value)
      }
    },
    selectLabel ({ key, label }) {
      // NOT persisted in DB in this form
      return `${label} [GID: ${key}]`
    }
  }
}
</script>
