<template>
  <div class="users" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('users.title') }}</h1>
    <!-- FORM -->
    <b-form v-if="showForm" @submit="onSubmit" @reset="onReset">
      <FormButtons @goBack="goBack" :has-delete-button="!!formReset.username" @delete="deleteUser(form)"/>
      <b-card class="mx-2 mx-md-5" :header="formCardHeader">
        <b-alert show v-if="isSamlUser" variant="warning">{{ $t('comp.users.isSamlUser.warning')}}</b-alert>
        <!-- FIELDS @FORM -->
        <b-form-group :label="$t('comp.users.username.label')" :description="$t('comp.users.username.description')">
          <b-form-input v-model="form.username" required :disabled="isSamlUser || formEdit"/>
        </b-form-group>
        <b-form-group v-if="!isSamlUser" :label="$t('comp.users.password.label')"
                      :description="$t('comp.users.password.description')">
          <b-form-input v-if="!isSamlUser" v-model="form.password" type="password" autocomplete="off"/>
        </b-form-group>
        <b-form-group :label="$t('comp.users.givenName.label')" :description="$t('comp.users.givenName.description')">
          <b-form-input v-model="form.givenName" :disabled="(isSamlUser && formReset.givenName !== null)"/>
        </b-form-group>
        <b-form-group :label="$t('comp.users.surname.label')" :description="$t('comp.users.surname.description')">
          <b-form-input v-model="form.surname" :disabled="(isSamlUser && formReset.surname !== null)"/>
        </b-form-group>
        <b-form-group :label="$t('comp.users.email.label')" :description="$t('comp.users.email.description')">
          <b-form-input v-model="form.email" :disabled="(isSamlUser && validEmail)"/>
        </b-form-group>
        <b-form-group :label="$t('comp.users.roles.label')" :description="$t('comp.users.roles.description')">
          <RoleSelect :selectedRoles.sync="form.localAuthorities" view="admin"/>
        </b-form-group>
        <b-form-group v-if="isSamlUser" :label="$t('comp.users.affiliations.label')" :description="$t('comp.users.affiliations.description')">
          <b-form-tags v-model="form.affiliations" :disabled="true" :required="false"  placeholder=""/>
        </b-form-group>
        <!-- BOOLEANS @FORM -->
        <b-form-checkbox v-model="form.enabled" name="enabled" switch>
          {{ $t('comp.users.enabled.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="form.accountLocked" name="accountLocked" switch>
          {{ $t('comp.users.accountLocked.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="form.accountExpired" name="accountExpired" switch>
          {{ $t('comp.users.accountExpired.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="form.credentialsExpired" name="credentialsExpired" switch>
          {{ $t('comp.users.credentialsExpired.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="form.hasGivenConsent" name="hasGivenConsent" switch>
          {{ $t('comp.users.hasGivenConsent.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="form.hasGivenTouconsent" name="hasGivenTouconsent" switch>
          {{ $t('comp.users.hasGivenTouconsent.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-if="form.subscriptions" v-model="form.subscriptions.nhrsysannounce" name="subscribedToSysannounce" switch>
          {{ $t('comp.users.subscriptions.nhrsysannounce.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-if="form.subscriptions" v-model="form.subscriptions.nhrusers" name="subscribedToUsers" switch>
          {{ $t('comp.users.subscriptions.nhrusers.label') }}
        </b-form-checkbox>
        <b-form-checkbox v-if="form.subscriptions" v-model="form.subscriptions.always" name="subscribedAlways" switch>
          {{ $t('comp.users.subscriptions.always.label') }}
        </b-form-checkbox>
      </b-card>
      <CommentCard :targetId="this.form.username.toString()" :new-comment.sync="newComment" :showTable="formEdit" view="admin"/>
    </b-form>
    <!-- TABLE -->
    <div class="overflow-auto px-2 px-md-5" v-else>
      <div class="row justify-content-between justify-content-md-start mt-lg-3">
        <div class="col-12 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="addUser" variant="success">
            <b-icon-plus/> {{ $t('comp.users.add.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-4 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('useronly')" variant="primary">
            <b-icon-funnel-fill v-if="userUsersFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.users.showUserOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-4 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('manager')" variant="primary">
            <b-icon-funnel-fill v-if="managerUsersFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.users.showManagerOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-4 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('pionly')" variant="primary">
            <b-icon-funnel-fill v-if="piUsersFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.users.showPiOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-4 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('advisor')" variant="primary">
            <b-icon-funnel-fill v-if="advisorUsersFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.users.showAdvisorOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-4 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('support')" variant="primary">
            <b-icon-funnel-fill v-if="supportUsersFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.users.showSupportOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-4 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="showOnly('admin')" variant="primary">
            <b-icon-funnel-fill v-if="adminUsersFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.users.showAdminOnly.label') }}
          </b-button>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="searchables" table-id="users-table"/>
      <b-table responsive id="users-table" ref="users-table" :busy.sync="isBusy" :fields="fields" :per-page="perPage"
               :current-page="currentPage" :filter="filter" :items="userItemProvider" small striped hover>
        <template v-slot:cell(actions)="data">
          <div style="max-width: 100px;">
            <b-button @click="editUser(data.item)" :title="$t('comp.users.edit.label')" variant="light"
                      size="sm" class="mr-1">
              <b-icon-pencil class="mr-1" variant="primary"/>
            </b-button>
            <b-button @click="deleteUser(data.item)" :title="$t('comp.users.delete.label')" variant="light"
                      size="sm" class="mr-1">
              <b-icon-trash class="mr-1" variant="danger"/>
            </b-button>
            <b-button v-b-modal="`accounts-modal-${data.item.username}`" :title="$t('comp.users.showaccounts.label')" variant="light"
                      size="sm" class="mt-1 mr-1">
              <b-icon-person-circle class="mr-1" variant="primary"/>
            </b-button>
            <b-button v-b-modal="`usage-modal-${data.item.username}`" :title="$t('comp.users.showusage.label')" variant="light"
                      size="sm" class="mt-1 mr-1">
              <b-icon-bar-chart-line class="mr-1" variant="info"/>
            </b-button>
          </div>
          <b-modal :id="`usage-modal-${data.item.username}`" :title="$t('comp.users.usage.display.label', { user: data.item.username })" size="xl">
            <div>
              <UserUsageInfo :user="data.item.username" view="admin"/>
            </div>
          </b-modal>
          <b-modal :id="`accounts-modal-${data.item.username}`" :title="$t('comp.users.useraccounts.label', { user: data.item.username, mail: data.item.email })" size="xl">
            <div>
              <UserAccounts :user="data.item" view="admin"/>
            </div>
          </b-modal>
        </template>
        <template v-slot:cell(email)="data">
          <a :href="`mailto:${data.item.email}?cc=hpc-support@fau.de&subject=HPC-Portal`">{{ data.item.email }}</a>
        </template>
        <template v-slot:cell(lastLoginType)="data">
          <div v-if="data.item.lastLoginType === 'local'">
            {{ data.item.lastLoginType }}
          </div>
          <b-button v-if="data.item.lastLoginType === 'saml'" :id="`remoteentity-popover${data.item.username}`" variant="light" class="px-2 py-1" style="border-color:darkgray">
            {{ data.item.lastLoginType }}
          </b-button>
          <b-popover v-if="data.item.lastLoginType === 'saml'" :target="`remoteentity-popover${data.item.username}`" triggers="hover" placement="right">
            <template #title>
              <b-icon-globe2 class="mr-1"/>
              {{ $t('comp.users.remoteentity.label') }}
            </template>
            <p v-if="data.item.saml"> {{ data.item.saml.remoteEntityID }} </p>
            <p v-else> {{ $t('comp.users.remoteentity.missing') }} </p>
          </b-popover>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { userFilterMixin } from '@/mixins/tableFilter.mixin'
import { userServiceForAdminView } from '@/services/user.service'
import { commentServiceForAdminView } from '@/services/comment.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import FormButtons from '@/components/generic/helper/FormButtons'
import CommentCard from '@/components/generic/helper/CommentCard'
import RoleSelect from '@/components/generic/select/RoleSelect'
import UserAccounts from '@/components/generic/helper/UserAccounts'
import UserUsageInfo from '@/components/generic/info/UserUsageInfo'

export default {
  name: 'Users',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, userFilterMixin],
  components: {
    TableHeader,
    FormButtons,
    CommentCard,
    RoleSelect,
    UserAccounts,
    UserUsageInfo
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: '',
      form: null,
      formEdit: false,
      formReset: null,
      showForm: false,
      newComment: '',
      changes: [],
      emptyForm: {
        username: '',
        givenName: '',
        surname: '',
        email: '',
        lastLogin: '',
        enabled: true,
        lastLoginType: '',
        accountLocked: false,
        accountExpired: false,
        credentialsExpired: false,
        localAuthorities: [],
        affiliations: [],
        hasGivenConsent: false,
        hasGivenTouconsent: false,
        subscriptions: {
          nhrsysannounce: true,
          nhrusers: false,
          always: false
        }
      }
    }
  },
  watch: {
  },
  computed: {
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false, searchable: false, thStyle: 'min-width:100px;' },
        { key: 'username' },
        { key: 'givenName' },
        { key: 'surname' },
        { key: 'email' },
        { key: 'lastLogin', date: true, searchable: false },
        { key: 'lastLoginType' },
        { key: 'enabled', boolean: true, searchable: false },
        { key: 'hasAccounts', array: true, sortable: false, searchable: false },
        { key: 'localAuthorities', array: true, capitalize: true, sortable: false },
        { key: 'touconsentDate', date: true, searchable: false }
      ]
      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.searchable == null) {
          field.searchable = true
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.users.${field.key}.label`)
        }
        if (field.boolean) {
          field.formatter = (value, key, item) => {
            return value ? this.$i18n.t('yes.label') : this.$i18n.t('no.label')
          }
          field.sortByFormatted = true
        }
        if (field.date) {
          field.formatter = (value, key, item) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
          }
          field.sortByFormatted = true
        }
        if (field.array) {
          field.formatter = (value) => {
            if (value !== null) {
              let arrayLabel = ''
              value.forEach((elem, index) => {
                if (field.capitalize) {
                  arrayLabel += elem.charAt(0).toUpperCase() + elem.slice(1)
                } else {
                  arrayLabel += elem
                }
                if (index !== value.length - 1) {
                  arrayLabel += ', '
                }
              })
              return arrayLabel
            }
          }
        }
      })
      return fields
    },
    isSamlUser () {
      if (this.form.lastLoginType != null && this.form.lastLoginType === 'saml') {
        return true
      }
      return false
    },
    formCardHeader () {
      if (this.formEdit === true) {
        return this.$i18n.t('comp.users.edit.label')
      } else {
        return this.$i18n.t('comp.users.add.label')
      }
    },
    searchables () {
      const localized = []
      this.fields.forEach((field) => {
        if (field.searchable === true) localized.push(this.$i18n.t(`comp.users.${field.key}.label`))
      })
      return localized
    },
    validEmail () {
      if (this?.formReset?.email) {
        // mail validator (Regex from https://v2.vuejs.org/v2/cookbook/form-validation.html)
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(this.formReset.email)
      } else {
        return false
      }
    }
  },
  created () {
    userServiceForAdminView.count({ filter: this.filter }).then((response) => (this.rows = response))
    userServiceForAdminView.count().then((response) => (this.totalRows = response))
  },
  beforeMount () {
  },
  methods: {
    refresh () {
      userServiceForAdminView.count().then((response) => (this.totalRows = response))
      if (this.$refs['users-table']) {
        this.$refs['users-table'].refresh()
      }
    },
    addUser () {
      this.formEdit = false
      this.formReset = _.cloneDeep(this.emptyForm)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
    },
    editUser (item) {
      this.formEdit = true
      this.formReset = _.cloneDeep(item)
      // Clean special field for table: Not to be persisted
      this.$delete(this.formReset, 'hasAccounts')
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.formEdit) {
        // Update User
        userServiceForAdminView.update(this.form).then(
          response => {
            // Only on Edit: Log changes or save comment if user save successful
            this.logChanges()
            this.makeToast(
              this.$i18n.t('updated.text', { id: this.form.username, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
          }
        ).catch(
          error => {
            this.changes = [] // Reset changes, will be refilled with next submssion
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.username }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        )
      } else {
        userServiceForAdminView.create(this.form).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: this.form.username, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
            this.refresh()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.username }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.form = _.cloneDeep(this.formReset)
      this.newComment = ''
      this.changes = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    deleteUser (user) {
      this.$bvModal.msgBoxConfirm(this.$i18n.t('sure.question'), {
        okVariant: 'danger',
        okTitle: this.$i18n.t('confirm.delete.label'),
        cancelTitle: this.$i18n.t('no.label')
      })
        .then(value => {
          if (value === true) {
            // Some formatting for cryptic placeholder IDs
            const formatted = user.username.replace(/\//g, '_')
            userServiceForAdminView.delete(encodeURIComponent(formatted)).then(
              (response) => {
                this.makeToast(
                  this.$i18n.t('deleted.text', { id: user.username, code: response.code }),
                  this.$i18n.t('result.success.title'),
                  'success'
                )
                if (this.formEdit) { this.goBack() }
                this.refresh()
              }
            ).catch(
              error => this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: error.message, id: user.username }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            )
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    goBack () {
      this.showForm = false
      this.form = null
      this.formReset = null
      this.formEdit = false
      this.newComment = ''
      this.changes = []
    },
    userItemProvider (ctx) {
      return userServiceForAdminView.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },
    logChanges () {
      Object.keys(this.form).forEach(key => {
        if (!_.isEqual(this.form[key], this.formReset[key])) {
          const newChange = { field: key, before: this.formReset[key], after: this.form[key] }
          this.changes.push(newChange)
        }
      })
      // Log Changes if any or if comment exists
      if (this.changes.length > 0 || this.newComment !== '') {
        commentServiceForAdminView.create({ targetId: this.form.username.toString(), comment: this.newComment, changes: this.changes }).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: 'Log: ' + response.comment, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
          }
        ).catch(
          error => {
            this.changes = [] // Reset changes, will be refilled with next submssion
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.title }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        )
      }
    }
  }
}
</script>
