<template>
  <div class="organizations" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('organizations.title') }}</h1>
    <div class="overflow-auto px-md-5">
      <b-alert variant="primary" class="my-3" show>
        <b-icon-info-circle class="mr-1"/>
        {{ $t('comp.organizations.sourceInformation.label' )}}
      </b-alert>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="searchables" table-id="organizations-table"/>
      <b-table responsive id="organizations-table" ref="organizations-table" :busy.sync="isBusy" :fields="fields"
               :per-page="perPage" :current-page="currentPage" :filter="filter" :items="organizationItemProvider"
               sort-by="displayName" small striped hover>
        <template v-slot:cell(informationUrl)="data">
          <a v-if="data.item.informationUrl" :href="data.item.informationUrl" target="_blank" rel="noopener">
            {{ data.item.informationUrl }}
          </a>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { organizationServiceForAdminView } from '@/services/organization.service'
import TableHeader from '@/components/generic/helper/TableHeader'

export default {
  name: 'Organizations',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    TableHeader
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: ''
    }
  },
  computed: {
    fields () {
      const fields = [
        { key: 'displayName' },
        { key: 'informationUrl' },
        { key: 'entityId' }
      ]
      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.searchable == null) {
          field.searchable = true
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.organizations.${field.key}.label`)
        }
      })
      return fields
    },
    searchables () {
      const localized = []
      this.fields.forEach((field) => {
        if (field.searchable === true) localized.push(this.$i18n.t(`comp.organizations.${field.key}.label`))
      })
      return localized
    }
  },
  created () {
    organizationServiceForAdminView.count({ filter: this.filter }).then((response) => (this.rows = response))
    organizationServiceForAdminView.count().then((response) => (this.totalRows = response))
  },
  methods: {
    refresh () {
      organizationServiceForAdminView.count().then((response) => (this.totalRows = response))
      if (this.$refs['organizations-table']) {
        this.$refs['organizations-table'].refresh()
      }
    },
    organizationItemProvider (ctx) {
      return organizationServiceForAdminView.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    }
  }
}
</script>
