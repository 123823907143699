<template>
  <div class="ranges" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('ranges.title') }}</h1>
    <b-form v-if="showForm" @submit="onSubmit" @reset="onReset">
      <FormButtons @goBack="goBack" :has-delete-button="!!formReset.range" @delete="deleteRange(form)"/>
      <b-card class="mx-2 mx-md-5" :header="formCardHeader">
        <b-form-group :label="$t('comp.ranges.rangeId.label')" :description="$t('comp.ranges.rangeId.description')">
          <b-form-input v-model="form.rangeId" trim :disabled="true" required/>
        </b-form-group>
        <b-form-group :label="$t('comp.ranges.type.label')" :description="$t('comp.ranges.type.description')">
          <b-form-input v-model="form.type" trim :disabled="formEdit" required/>
        </b-form-group>
        <b-form-group :label="$t('comp.ranges.startValue.label')" :description="$t('comp.ranges.startValue.description')">
          <b-form-input v-model="form.startValue" trim required number/>
        </b-form-group>
        <b-form-group :label="$t('comp.ranges.endValue.label')" :description="$t('comp.ranges.endValue.description')">
          <b-form-input v-model="form.endValue" trim required number/>
        </b-form-group>
        <b-form-group :label="$t('comp.ranges.nextValue.label')" :description="$t('comp.ranges.nextValue.description')">
          <b-form-input v-model="form.nextValue" trim number/>
        </b-form-group>
        <b-form-group :label="$t('comp.ranges.priority.label')" :description="$t('comp.ranges.priority.description')">
          <b-form-input v-model="form.priority" trim required number/>
        </b-form-group>
      </b-card>
    </b-form>
    <div v-else class="overflow-auto px-2 px-md-5">
      <div class="row justify-content-md-start mb-3 mt-lg-3">
        <div class="col-12 col-md-auto">
          <b-button class="w-100" @click="addRange" variant="success">
            <b-icon-plus/> {{ $t('comp.ranges.add.label') }}
          </b-button>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="searchables" table-id="ranges-table"/>
      <b-table responsive id="ranges-table" ref="ranges-table" :busy.sync="isBusy" :fields="fields" :per-page="perPage"
               :current-page="currentPage" :filter="filter" :items="rangeItemProvider" small striped hover>
        <template v-slot:cell(actions)="data">
          <b-button @click="editRange(data.item)" :title="$t('comp.ranges.edit.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-pencil class="mr-1" variant="primary"/>
          </b-button>
          <b-button @click="deleteRange(data.item)" :title="$t('comp.ranges.delete.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-trash class="mr-1" variant="danger"/>
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { rangeServiceForAdminView } from '@/services/range.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import FormButtons from '@/components/generic/helper/FormButtons'

export default {
  name: 'Ranges',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    TableHeader,
    FormButtons
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: '',
      form: null,
      formEdit: false,
      formReset: null,
      showForm: false,
      emptyForm: {
        rangeId: null,
        type: '',
        startValue: null,
        endValue: null,
        nextValue: null,
        priority: null
      }
    }
  },
  computed: {
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false, searchable: false },
        { key: 'rangeId' },
        { key: 'type' },
        { key: 'startValue', searchable: false },
        { key: 'endValue', searchable: false },
        { key: 'nextValue', searchable: false },
        { key: 'available', available: true, searchable: false },
        { key: 'priority', searchable: false }
      ]
      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.searchable == null) {
          field.searchable = true
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.ranges.${field.key}.label`)
        }
      })
      return fields
    },
    formCardHeader () {
      if (this.formEdit === true) {
        return this.$i18n.t('comp.ranges.edit.label')
      } else {
        return this.$i18n.t('comp.ranges.add.label')
      }
    },
    searchables () {
      const localized = []
      this.fields.forEach((field) => {
        if (field.searchable === true) localized.push(this.$i18n.t(`comp.ranges.${field.key}.label`))
      })
      return localized
    }
  },
  created () {
    rangeServiceForAdminView.count({ filter: this.filter }).then((response) => (this.rows = response))
    rangeServiceForAdminView.count().then((response) => (this.totalRows = response))
  },
  methods: {
    refresh () {
      rangeServiceForAdminView.count().then((response) => (this.totalRows = response))
      if (this.$refs['ranges-table']) {
        this.$refs['ranges-table'].refresh()
      }
    },
    addRange () {
      this.formEdit = false
      this.formReset = _.cloneDeep(this.emptyForm)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
    },
    editRange (item) {
      this.formEdit = true
      this.formReset = _.cloneDeep(item)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.formEdit) {
        rangeServiceForAdminView.update(this.form).then(
          response => {
            this.makeToast(
              this.$i18n.t('updated.text', { id: this.form.rangeId, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.rangeId }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      } else {
        rangeServiceForAdminView.create(this.form).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: response.rangeId, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
            this.refresh()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.type }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      }
    },
    onReset (evt) {
      evt.preventDefault()
      this.form = _.cloneDeep(this.formReset)
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    deleteRange (range) {
      this.$bvModal.msgBoxConfirm(this.$i18n.t('sure.question'), {
        okVariant: 'danger',
        okTitle: this.$i18n.t('confirm.delete.label'),
        cancelTitle: this.$i18n.t('no.label')
      })
        .then(value => {
          if (value === true) {
            rangeServiceForAdminView.delete(range.rangeId).then(
              (response) => {
                this.makeToast(
                  this.$i18n.t('deleted.text', { id: range.rangeId, code: response.code }),
                  this.$i18n.t('result.success.title'),
                  'success')
                if (this.formEdit) { this.goBack() }
                this.refresh()
              }
            ).catch(
              error => this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: error.message, id: range.rangeId }),
                this.$i18n.t('result.error.title'),
                'danger')
            )
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    goBack () {
      this.showForm = false
      this.form = null
      this.formReset = null
      this.formEdit = false
    },
    rangeItemProvider (ctx) {
      return rangeServiceForAdminView.list(ctx).then((data) => {
        this.rows = data.count
        _.each(data.items, (item) => {
          item.available = (item.endValue - item.startValue) - (item.nextValue - item.startValue - 1)
        })
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>
