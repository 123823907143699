<template>
  <div class="provinces" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('provinces.title') }}</h1>
    <b-form v-if="showForm" @submit="onSubmit" @reset="onReset">
      <FormButtons @goBack="goBack" :has-delete-button="!!formReset.province" @delete="deleteProvince(form)"/>
      <b-card class="mx-2 mx-md-5" :header="formCardHeader">
        <b-form-group :label="$t('comp.provinces.provinceCode.label')"
                      :description="$t('comp.provinces.provinceCode.description')">
          <b-form-input v-model="form.provinceCode" required trim/>
        </b-form-group>
        <b-form-group :label="$t('comp.provinces.projectCode.label')"
                      :description="$t('comp.provinces.projectCode.description')">
          <b-form-input v-model="form.projectCode" required trim/>
        </b-form-group>
        <!-- Add List of Orgs only for edit with :disabled="formEdit" ? -->
      </b-card>
    </b-form>
    <div v-else class="overflow-auto px-2 px-md-5">
      <div class="row justify-content-md-start mb-3 mt-lg-3">
        <div class="col-12 col-md-auto">
          <b-button class="w-100" @click="addProvince" variant="success">
            <b-icon-plus/> {{ $t('comp.provinces.add.label') }}
          </b-button>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="searchables" table-id="provinces-table"/>
      <b-table responsive id="provinces-table" ref="provinces-table" :busy.sync="isBusy" :fields="fields" :per-page="perPage"
               :current-page="currentPage" :filter="filter" :items="provinceItemProvider" small striped hover>
        <template v-slot:cell(actions)="data">
          <b-button @click="editProvince(data.item)" :title="$t('comp.provinces.edit.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-pencil class="mr-1" variant="primary"/>
          </b-button>
          <b-button @click="deleteProvince(data.item)" :title="$t('comp.provinces.delete.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-trash class="mr-1" variant="danger"/>
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { provinceServiceForAdminView } from '@/services/province.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import FormButtons from '@/components/generic/helper/FormButtons'
import { i18nMixin } from '@/mixins/i18n.mixin'
import _ from 'lodash'

export default {
  name: 'Provinces',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    TableHeader,
    FormButtons
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: '',
      form: null,
      formEdit: false,
      formReset: null,
      showForm: false,
      emptyForm: {
        provinceCode: '',
        projectCode: ''
      }
    }
  },
  computed: {
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false, searchable: false },
        { key: 'localized', localize: true, sortable: false, searchable: false },
        { key: 'provinceCode' },
        { key: 'projectCode', searchable: false }
      ]
      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.searchable == null) {
          field.searchable = true
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.provinces.${field.key}.label`)
        }
        if (field.localize) {
          field.formatter = (value) => {
            return this.$i18n.t(`provinces.${value}.label`)
          }
        }
      })
      return fields
    },
    formCardHeader () {
      if (this.formEdit === true) {
        return this.$i18n.t('comp.provinces.edit.label')
      } else {
        return this.$i18n.t('comp.provinces.add.label')
      }
    },
    searchables () {
      const localized = []
      this.fields.forEach((field) => {
        if (field.searchable === true) localized.push(this.$i18n.t(`comp.provinces.${field.key}.label`))
      })
      return localized
    }
  },
  created () {
    provinceServiceForAdminView.count({ filter: this.filter }).then((response) => (this.rows = response))
    provinceServiceForAdminView.count().then((response) => (this.totalRows = response))
  },
  methods: {
    refresh () {
      provinceServiceForAdminView.count().then((response) => (this.totalRows = response))
      if (this.$refs['provinces-table']) {
        this.$refs['provinces-table'].refresh()
      }
    },
    addProvince () {
      this.formEdit = false
      this.formReset = _.cloneDeep(this.emptyForm)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
    },
    editProvince (item) {
      this.formEdit = true
      this.formReset = _.cloneDeep(item)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.formEdit) {
        provinceServiceForAdminView.update(this.form).then(
          response => {
            this.makeToast(
              this.$i18n.t('updated.text', { id: this.form.name, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.name }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      } else {
        provinceServiceForAdminView.create(this.form).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: this.form.name, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
            this.refresh()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.name }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.form = _.cloneDeep(this.formReset)
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    deleteProvince (province) {
      this.$bvModal.msgBoxConfirm(this.$i18n.t('sure.question'), {
        okVariant: 'danger',
        okTitle: this.$i18n.t('confirm.delete.label'),
        cancelTitle: this.$i18n.t('no.label')
      })
        .then(value => {
          if (value === true) {
            provinceServiceForAdminView.delete(province.provinceCode).then(
              (response) => {
                this.makeToast(
                  this.$i18n.t('deleted.text', { id: province.provinceCode, code: response.code }),
                  this.$i18n.t('result.success.title'),
                  'success')
                if (this.formEdit) { this.goBack() }
                this.refresh()
              }
            ).catch(
              error => this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: error.message, id: province.provinceCode }),
                this.$i18n.t('result.error.title'),
                'danger')
            )
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    goBack () {
      this.showForm = false
      this.form = null
      this.formReset = null
      this.formEdit = false
    },
    provinceItemProvider (ctx) {
      return provinceServiceForAdminView.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>
