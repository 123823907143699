<template>
  <div class="projectResourceSelect" v-show="messagesAvailable">
    <b-row align-h="between">
      <b-form class="w-100" inline>
        <b-col cols="8" md="4" class="mb-2 pr-2">
          <ResourceSelect class="w-100" :selected-resource.sync="selectedResource" :view="this.view" :resourceType="this.resourceType" required/>
        </b-col>
        <b-col cols="4" md="2" class="mb-2 pl-2 px-md-2">
          <b-form-input class="w-100" v-model="quota" type="number" min="1" required/>
        </b-col>
        <b-col cols="6" md="3" class="mb-2 pr-2 px-md-2">
          <b-button class="w-100" variant="primary" @click="addResource" :title="$t('comp.quota.add.label')">
            {{ $t('comp.projectresourceselect.add.label') }}
          </b-button>
        </b-col>
        <b-col cols="6" md="3" class="mb-2 pl-2">
          <b-button class="w-100" variant="primary" @click="reduceResource" :title="$t('comp.quota.reduce.label')">
            {{ $t('comp.projectresourceselect.reduce.label') }}
          </b-button>
        </b-col>
      </b-form>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <multiselect v-model="selected" :options="options" @input="onInput" track-by="key" label="label"
                      :multiple="isMultiple" :close-on-select="isCloseOnSelect" :internal-search="false"
                      :clear-on-select="isClearOnSelect" :preserve-search="false" :preselect-first="false"
                      :placeholder="$t('comp.projectresourceselect.resources.placeholder')" :selectLabel="$t('select.selectLabel')"
                      :selectedLabel="$t('select.selectedLabel')" :deselectLabel="$t('select.deselectLabel')"
                      :blockKeys="['Delete']" :disabled="isDisabled">
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Multiselect from 'vue-multiselect'
import ResourceSelect from '@/components/generic/select/ResourceSelect'

export default {
  name: 'ProjectResourceSelect',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Multiselect,
    ResourceSelect
  },
  props: {
    selectedProjectResource: {
      type: Object,
      default: null
    },
    selectedProjectResources: {
      type: Array,
      default: null
    },
    view: {
      type: String,
      required: true
    },
    resourceType: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: [],
      selected: null,
      isMultiple: true,
      selectedResource: null,
      quota: 1,
      unit: ''
    }
  },
  beforeMount () {
    this.setUnit()
    this.isMultiple ? this.preProcessSelectedProjectResources() : this.preProcessSelectedProjectResource()
  },
  computed: {
    isCloseOnSelect () {
      return !this.isMultiple
    },
    isClearOnSelect () {
      return this.isMultiple
    }
  },
  watch: {
    selectedProjectResource: function () {
      this.preProcessSelectedProjectResource()
    },
    selectedProjectResources: function () {
      this.preProcessSelectedProjectResources()
    }
  },
  methods: {
    reset () {
      this.options = []
    },
    resetResource () {
      this.selectedResource = null
      this.quota = 1
    },
    setUnit () {
      if (this.resourceType === 'computing') {
        this.unit = 'h'
      } else if (this.resourceType === 'storage') {
        this.unit = 'TB'
      }
    },
    onInput (value) {
      if (this.isMultiple) {
        this.$emit('update:selectedProjectResources', value)
      } else {
        this.$emit('update:selectedProjectResource', value)
      }
    },
    preProcessSelectedProjectResource () {
      this.selected = this.selectedProjectResource ? this.selectedProjectResource : null
    },
    preProcessSelectedProjectResources () {
      this.selected = this.selectedProjectResources ? this.selectedProjectResources : []
    },
    addResource () {
      if (this.quota > 0 && this.selectedResource) {
        const newResource = { key: this.selectedResource.key, label: (this.selectedResource.label + ':' + this.quota + this.unit), quota: this.quota }
        const existingResource = this.selected.filter(function (elem) {
          if (elem.key === newResource.key) return elem
        })

        if (existingResource.length === 0) { // Check if resourceEntry exists
          this.selected.push(newResource)
          this.onInput(this.selected)
          this.resetResource()
        } else { // Update Quota if so
          const existingIndex = this.selected.indexOf(existingResource[0])
          const updatedQuota = Number(existingResource[0].quota) + Number(newResource.quota)
          const updatedResource = { key: newResource.key, label: (this.selectedResource.label + ':' + updatedQuota + this.unit), quota: updatedQuota }
          this.selected.splice(existingIndex, 1, updatedResource) // Replace 1 item at existingIndex with updatedResource
          this.onInput(this.selected)
          this.resetResource()
        }
      }
    },
    reduceResource () {
      if (this.quota > 0 && this.selectedResource) {
        const selectedKey = this.selectedResource.key
        const existingResource = this.selected.filter(function (elem) {
          if (elem.key === selectedKey) return elem
        })

        if (existingResource.length === 0) { // Check if resourceEntry exists
          console.log('Cannot reduce quota if resource does not exist!')
        } else { // Update Quota if not negative
          const updatedQuota = Number(existingResource[0].quota) - Number(this.quota)
          if (updatedQuota <= 0) {
            console.log('Cannot reduce quota under a minimum of 1!')
          } else {
            const existingIndex = this.selected.indexOf(existingResource[0])
            const updatedResource = { key: this.selectedResource.key, label: (this.selectedResource.label + ':' + updatedQuota + this.unit), quota: updatedQuota }
            this.selected.splice(existingIndex, 1, updatedResource) // Replace 1 item at existingIndex with updatedResource
            this.onInput(this.selected)
            this.resetResource()
          }
        }
      }
    }
  }
}
</script>
