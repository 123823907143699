<template>
  <div class="domainSelect" v-show="messagesAvailable">
    <multiselect v-model="selected" :options="options" @input="onInput" track-by="key" label="label"
                 :multiple="false" :close-on-select="true" :internal-search="true"
                 :clear-on-select="false" :preserve-search="false" :preselect-first="false"
                 :placeholder="placeholder" :selectLabel="$t('select.selectLabel')"
                 :selectedLabel="$t('select.selectedLabel')" :deselectLabel="$t('select.deselectLabel')"
                 :blockKeys="['Delete']" :disabled="isDisabled" :custom-label="selectLocalizedLabel">
    </multiselect>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import { domainServiceForAdminView } from '@/services/domain.service'
import Multiselect from 'vue-multiselect'

export default {
  name: 'DomainSelect',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Multiselect
  },
  props: {
    selectedDomain: {
      type: Object,
      default: null
    },
    view: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: [],
      selected: null
    }
  },
  beforeMount () {
    this.loadDomainOptions()
    if (this.selectedDomain) {
      this.processSelectedDomain()
    }
  },
  computed: {
    placeholder () {
      return this.$i18n.t('comp.domainselect.domain.placeholder')
    }
  },
  watch: {
    selectedDomain: function () {
      this.processSelectedDomain()
    }
  },
  methods: {
    loadDomainOptions () {
      if (this.view === 'admin') {
        domainServiceForAdminView.select().then((domains) => {
          this.options = domains // Sorted in Backend
        }).catch(error => {
          console.error(error)
        })
      }
    },
    processSelectedDomain () {
      this.selected = this.selectedDomain
    },
    onInput (option) {
      this.$emit('update:selectedDomain', option)
    },
    selectLocalizedLabel ({ key, label }) {
      if (label.en && this.$i18n.locale === 'en') {
        return key + ': ' + label.en
      } else if (label.de && this.$i18n.locale === 'de') {
        return key + ': ' + label.de
      } else {
        return key
      }
    }
  }
}
</script>
