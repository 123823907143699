import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'importJson'

const VIEW_ADMIN = 'admin'

class ImportService {
  constructor (view) {
    this.view = view
  }

  create (data, params = {}) {
    params.view = this.view
    return DefaultApiService.create(ENDPOINT, data, params)
  }
}

export default ImportService
export const importServiceForAdminView = new ImportService(VIEW_ADMIN)
