<template>
  <div class="localization" v-show="messagesAvailable">
    <h2 class="sr-only">{{ $t('localization.title') }}</h2>
    <b-form v-if="showForm" @submit="onSubmit" @reset="onReset">
      <FormButtons @goBack="goBack" :has-delete-button="!!formReset.key" @delete="deleteLocaleMessage(form)"/>
      <b-card class="mx-2 mx-md-5" :header="formCardHeader">
        <b-form-group id="input-group-key" :label="$t('comp.localization.key.label')" label-for="input-key"
                      :description="$t('comp.localization.key.description')">
          <b-form-input id="input-key" v-model="form.key" required :readonly="formEdit"/>
        </b-form-group>
        <b-form-group :id="'input-group-locale-'+lang"
                      :label="$t('comp.localization.translation.label', { lang: lang })" :label-for="'input-'+lang"
                      :description="$t('comp.localization.translation.description')" v-for="lang in form.orderedLangs"
                      v-bind:key="lang" size="sm">
          <b-input-group :prepend="lang">
            <b-form-input :id="'input-'+lang" v-model="form.locales[lang]"/>
          </b-input-group>
        </b-form-group>
        <div>
          <b-badge variant="warning" v-for="locale in formMissingItems" :key="locale" class="d-block mb-1 ml-1">
            {{ $t('comp.localization.missing', {lang: locale, values: JSON.stringify(form.missing[locale])}) }}
          </b-badge>
        </div>
      </b-card>
    </b-form>
    <div v-else class="overflow-auto px-2 px-md-5">
      <div class="row justify-content-md-start mb-3 mt-lg-3">
        <div class="col-12 col-md-auto">
          <b-button class="w-100" @click="addLocaleMessage" variant="success">
            <b-icon-plus/> {{ $t('comp.localization.add.label') }}
          </b-button>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="[this.$i18n.t('comp.localization.key.label'), 'en', 'de']" table-id="localization-table"/>
      <b-table responsive id="localization-table" ref="localization-table" :busy.sync="isBusy" :fields="fields"
               :per-page="perPage" :current-page="currentPage" :filter="filter" :items="localeMessageItemProvider"
               small striped hover>
        <template v-slot:cell(actions)="data">
          <b-button @click="editLocaleMessage(data.item)" :title="$t('comp.localization.edit.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-pencil class="mr-1" variant="primary"/>
          </b-button>
          <b-button @click="deleteLocaleMessage(data.item)" :title="$t('comp.localization.delete.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-trash class="mr-1" variant="danger"/>
          </b-button>
        </template>
        <template v-slot:[goToCell(lang)]="data" v-for="lang in langs">
          {{ getLocaleMessage(data.item, lang) }}
          <b-badge variant="warning" v-if="isLocaleMissing(data.item, lang)" :key="lang">missing</b-badge>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import LocaleMessageService from '@/services/localeMessage.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import FormButtons from '@/components/generic/helper/FormButtons'
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'Localization',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    TableHeader,
    FormButtons
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: '',
      form: null,
      formEdit: false,
      formReset: null,
      showForm: false,
      emptyForm: {
        key: '',
        locales: {}
      },
      langs: ['en', 'de']
    }
  },
  watch: {
  },
  computed: {
    formMissingItems () {
      if (_.has(this.form, 'missing')) {
        return _.keys(this.form.missing)
      }
      return []
    },
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false },
        { key: 'key', label: this.$i18n.t('comp.localization.key.label'), sortable: true }
      ]
      _.each(this.langs, (lang) => {
        fields.push({ key: lang, label: lang, sortable: true })
      })
      return fields
    },
    formCardHeader () {
      if (this.formEdit === true) {
        return this.$i18n.t('comp.localization.edit.label')
      } else {
        return this.$i18n.t('comp.localization.add.label')
      }
    }
  },
  created () {
    LocaleMessageService.count({ filter: this.filter }).then((response) => (this.rows = response))
    LocaleMessageService.count().then((response) => (this.totalRows = response))
  },
  methods: {
    refresh () {
      LocaleMessageService.count().then((response) => (this.totalRows = response))
      if (this.$refs['localization-table']) {
        this.$refs['localization-table'].refresh()
      }
    },
    goToCell (key) {
      return `cell(${key})`
    },
    getLocaleMessage (item, lang) {
      if (_.has(item, 'locales.' + lang)) {
        return item.locales[lang]
      }
      return ''
    },
    isLocaleMissing (item, lang) {
      return _.has(item, 'missing.' + lang)
    },
    prepareLocales (item) {
      if (!('locales' in item)) {
        item.locales = {}
      }
      const orderedLangs = []
      const itemLangs = _.keys(item.locales)
      _.each(this.langs, (lang) => {
        const locale = _.head(_.remove(itemLangs, (o) => { return o === lang }))
        if (locale === undefined) {
          item.locales[lang] = ''
        }
        orderedLangs.push(lang)
      })
      item.orderedLangs = orderedLangs.concat(itemLangs)
    },
    addLocaleMessage () {
      this.formEdit = false
      this.formReset = _.cloneDeep(this.emptyForm)
      this.prepareLocales(this.formReset)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
    },
    editLocaleMessage (item) {
      this.formEdit = true
      this.formReset = _.cloneDeep(item)
      this.prepareLocales(this.formReset)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
    },
    onSubmit (evt) {
      evt.preventDefault()
      delete this.form.orderedLangs
      if (this.formEdit) {
        const missingItemsToDelete = []
        _.each(this.formMissingItems, (missing) => {
          if (!_.isEmpty(this.getLocaleMessage(this.form, missing))) {
            missingItemsToDelete.push(missing)
          }
        })
        _.each(missingItemsToDelete, (missing) => delete this.form.missing[missing])
        LocaleMessageService.update(this.form).then(
          response => {
            this.makeToast(
              this.$i18n.t('updated.text', { id: this.form.key, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.key }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      } else {
        LocaleMessageService.create(this.form).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: this.form.key, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
            this.refresh()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.key }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.form = _.cloneDeep(this.formReset)
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    deleteLocaleMessage (localeMessage) {
      this.$bvModal.msgBoxConfirm(this.$i18n.t('sure.question'), {
        okVariant: 'danger',
        okTitle: this.$i18n.t('confirm.delete.label'),
        cancelTitle: this.$i18n.t('no.label')
      })
        .then(value => {
          if (value === true) {
            LocaleMessageService.delete(localeMessage.key).then(
              (response) => {
                this.makeToast(
                  this.$i18n.t('deleted.text', { id: localeMessage.key, code: response.code }),
                  this.$i18n.t('result.success.title'),
                  'success'
                )
                if (this.formEdit) { this.goBack() }
                this.refresh()
              }
            ).catch(
              error => this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: error.message, id: localeMessage.key }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            )
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    goBack () {
      this.showForm = false
      this.form = null
      this.formReset = null
    },
    localeMessageItemProvider (ctx) {
      return LocaleMessageService.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>
