import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'organizations'

const VIEW_ADMIN = 'admin'
const VIEW_MANAGER = 'manager'
const VIEW_USER = 'user'

class OrganizationService {
  constructor (view) {
    this.view = view
  }

  count (params = {}) {
    params.view = this.view
    params.type = 'metadata'
    return DefaultApiService.count(ENDPOINT, params)
  }

  list (params = {}) {
    params.view = this.view
    params.type = 'table'
    return DefaultApiService.list(ENDPOINT, params)
  }

  select (params = {}) {
    params.view = this.view
    params.type = 'select'
    return DefaultApiService.list(ENDPOINT, params)
  }

  get (id, params = {}) {
    params.view = this.view
    return DefaultApiService.get(ENDPOINT, id, params)
  }
}

export default OrganizationService
export const organizationServiceForUserView = new OrganizationService(VIEW_USER)
export const organizationServiceForManagerView = new OrganizationService(VIEW_MANAGER)
export const organizationServiceForAdminView = new OrganizationService(VIEW_ADMIN)
