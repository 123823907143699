<template>
  <div class="resources" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('resources.title') }}</h1>
    <b-form v-if="showForm" @submit="onSubmit" @reset="onReset">
      <FormButtons @goBack="goBack" :has-delete-button="!!formReset.resource" @delete="deleteResource(form)"/>
      <b-card class="mx-2 mx-md-5" :header="formCardHeader">
        <b-form-group :label="$t('comp.resources.resourceid.label')"
                      :description="$t('comp.resources.resourceid.description')">
          <b-form-input v-model="form.resourceId" required trim disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.resources.cluster.label')"
                      :description="$t('comp.resources.cluster.description')">
          <b-form-input v-model="form.cluster" required trim/>
        </b-form-group>
        <b-form-group :label="$t('comp.resources.subcluster.label')"
                      :description="$t('comp.resources.subcluster.description')">
          <b-form-input v-model="form.subCluster" required trim/>
        </b-form-group>
        <b-form-group :label="$t('comp.resources.type.label')"
                      :description="$t('comp.resources.type.description')">
          <multiselect v-model="form.type" :options="typeOptions" :placeholder="$t('comp.resources.type.placeholder')"
                      :multiple="false"/>
        </b-form-group>
        <b-form-group :label="$t('comp.resources.state.label')" label-for="state"
                      :description="$t('comp.resources.state.description')">
          <StateSelect :selected-state.sync="form.state" entity="resource" view="admin"/>
        </b-form-group>
        <b-form-group :label="$t('comp.resources.activeUntil.label')" label-for="active-until"
                      :description="$t('comp.resources.activeUntil.description')">
          <b-form-datepicker no-flip id="active-until" :showDecadeNav="showDateTimeDecadeNav" v-model="form.activeUntil"
                             v-bind="datePickerLabels || {}" class="mb-2" :value-as-date="true"/>
        </b-form-group>
      </b-card>
    </b-form>
    <div v-else class="overflow-auto px-2 px-md-5">
      <div class="row justify-content-md-start mb-3 mt-lg-3">
        <div class="col-12 col-md-auto">
          <b-button class="w-100" @click="addResource" variant="success">
            <b-icon-plus/> {{ $t('comp.resources.add.label') }}
          </b-button>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="searchables" table-id="resources-table"/>
      <b-table responsive id="resources-table" ref="resources-table" :busy.sync="isBusy" :fields="fields" :per-page="perPage"
               :current-page="currentPage" :filter="filter" :items="resourceItemProvider" small striped hover>
        <template v-slot:cell(actions)="data">
          <b-button @click="editResource(data.item)" :title="$t('comp.resources.edit.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-pencil class="mr-1" variant="primary"/>
          </b-button>
          <b-button @click="deleteResource(data.item)" :title="$t('comp.resources.delete.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-trash class="mr-1" variant="danger"/>
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { resourceServiceForAdminView } from '@/services/resource.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import FormButtons from '@/components/generic/helper/FormButtons'
import StateSelect from '@/components/generic/select/StateSelect'
import { i18nMixin } from '@/mixins/i18n.mixin'
import datepickerMixin from '@/mixins/datepicker.mixin'
import _ from 'lodash'
import moment from 'moment'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Resources',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, datepickerMixin],
  components: {
    TableHeader,
    FormButtons,
    StateSelect,
    Multiselect
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: '',
      showDateTimeDecadeNav: true,
      form: null,
      formEdit: false,
      formReset: null,
      showForm: false,
      emptyForm: {
        resourceId: null,
        cluster: '',
        subCluster: '',
        type: '',
        state: '',
        activeUntil: ''
      },
      typeOptions: ['computing', 'storage']
    }
  },
  computed: {
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false, searchable: false },
        { key: 'resourceId' },
        { key: 'cluster' },
        { key: 'subCluster' },
        { key: 'type', localize: true },
        { key: 'state', localize: true },
        { key: 'activeUntil', date: true, searchable: false },
        { key: 'createdAt', date: true, searchable: false }
      ]
      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.searchable == null) {
          field.searchable = true
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.resources.${field.key}.label`)
        }
        if (field.localize) {
          field.formatter = (value) => {
            return this.$i18n.t(`resources.type.${value}.label`)
          }
        }
        if (field.date) {
          field.formatter = (value, key, item) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
          }
          field.sortByFormatted = true
        }
      })
      return fields
    },
    formCardHeader () {
      if (this.formEdit === true) {
        return this.$i18n.t('comp.resources.edit.label')
      } else {
        return this.$i18n.t('comp.resources.add.label')
      }
    },
    searchables () {
      const localized = []
      this.fields.forEach((field) => {
        if (field.searchable === true) localized.push(this.$i18n.t(`comp.resources.${field.key}.label`))
      })
      return localized
    }
  },
  created () {
    resourceServiceForAdminView.count({ filter: this.filter }).then((response) => (this.rows = response))
    resourceServiceForAdminView.count().then((response) => (this.totalRows = response))
  },
  methods: {
    refresh () {
      resourceServiceForAdminView.count().then((response) => (this.totalRows = response))
      if (this.$refs['resources-table']) {
        this.$refs['resources-table'].refresh()
      }
    },
    addResource () {
      this.formEdit = false
      this.formReset = _.cloneDeep(this.emptyForm)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
    },
    editResource (item) {
      this.formEdit = true
      item.activeUntil = item?.activeUntil ? new Date(item.activeUntil) : null // Format to JSDate for display
      this.formReset = _.cloneDeep(item)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.formEdit) {
        resourceServiceForAdminView.update(this.form).then(
          response => {
            this.makeToast(
              this.$i18n.t('updated.text', { id: this.form.name, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.name }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      } else {
        resourceServiceForAdminView.create(this.form).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: this.form.name, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
            this.refresh()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.name }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.form = _.cloneDeep(this.formReset)
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    deleteResource (resource) {
      this.$bvModal.msgBoxConfirm(this.$i18n.t('sure.question'), {
        okVariant: 'danger',
        okTitle: this.$i18n.t('confirm.delete.label'),
        cancelTitle: this.$i18n.t('no.label')
      })
        .then(value => {
          if (value === true) {
            resourceServiceForAdminView.delete(resource.resourceId).then(
              (response) => {
                this.makeToast(
                  this.$i18n.t('deleted.text', { id: resource.resourceId, code: response.code }),
                  this.$i18n.t('result.success.title'),
                  'success')
                if (this.formEdit) { this.goBack() }
                this.refresh()
              }
            ).catch(
              error => this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: error.message, id: resource.resourceId }),
                this.$i18n.t('result.error.title'),
                'danger')
            )
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    goBack () {
      this.showForm = false
      this.form = null
      this.formReset = null
      this.formEdit = false
    },
    resourceItemProvider (ctx) {
      return resourceServiceForAdminView.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>
