<template>
  <div class="provinceSelect" v-show="messagesAvailable">
    <multiselect v-model="selected" :options="options" @input="onInput" track-by="key" label="label"
                 :multiple="isMultiple" :close-on-select="isCloseOnSelect" :internal-search="true"
                 :clear-on-select="isClearOnSelect" :preserve-search="false" :preselect-first="false"
                 :placeholder="placeholder" :selectLabel="$t('select.selectLabel')"
                 :selectedLabel="$t('select.selectedLabel')" :deselectLabel="$t('select.deselectLabel')"
                 :blockKeys="['Delete']" :disabled="isDisabled">
    </multiselect>
  </div>

</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import Multiselect from 'vue-multiselect'
import ProvinceService from '@/services/province.service'

export default {
  name: 'ProvinceSelect',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Multiselect
  },
  props: {
    selectedProvince: {
      type: String,
      default: null
    },
    selectedProvinces: {
      type: Array,
      default: null
    },
    view: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMultiple: false,
      options: [],
      selected: null
    }
  },
  beforeMount () {
    this.setIsMultiple()
    this.loadProvinceOptions()
    this.isMultiple ? this.preProcessSelectedProvinces() : this.preProcessSelectedProvince()
  },
  computed: {
    isCloseOnSelect () {
      return !this.isMultiple
    },
    isClearOnSelect () {
      return this.isMultiple
    },
    placeholder () {
      if (this.isMultiple) {
        return this.$i18n.t('comp.provinceselect.provinces.placeholder')
      } else {
        return this.$i18n.t('comp.provinceselect.province.placeholder')
      }
    }
  },
  watch: {
    selectedProvince: function () {
      this.preProcessSelectedProvince()
    },
    selectedProvinces: function () {
      this.preProcessSelectedProvinces()
    }
  },
  methods: {
    setIsMultiple () {
      if (this.selectedProvinces) { this.isMultiple = true }
    },
    loadProvinceOptions () {
      if (this.view === 'admin') {
        const provinceService = new ProvinceService(this.view)
        provinceService.select().then((provinces) => {
          this.options = provinces
        }).then(() => {
          this.preProcessOptions()
        }).catch(error => {
          console.error(error)
        })
      }
    },
    generateLabel (key) {
      return this.messagesAvailable ? this.$i18n.t(`provinces.${key}.label`) : key
    },
    preProcessOptions () {
      _.each(this.options, (option) => {
        option.label = this.generateLabel(option.key)
      })
      this.options.sort((a, b) => String(a.label).localeCompare(String(b.label)))
    },
    preProcessSelectedProvince () {
      this.selected = this.selectedProvince
        ? { key: this.selectedProvince, label: this.generateLabel(this.selectedProvince) }
        : null
    },
    postProcessSelectedProvince (selectedProvince) {
      return selectedProvince && selectedProvince.key ? selectedProvince.key : ''
    },
    preProcessSelectedProvinces () {
      const provinces = []
      if (this.selectedProvinces) {
        _.each(this.selectedProvinces, (province) => {
          provinces.push({ key: province, label: this.generateLabel(province) })
        })
      }
      this.selected = provinces
    },
    postProcessSelectedProvinces (selectedProvinces) {
      return selectedProvinces ? _.map(selectedProvinces, 'key') : []
    },
    onInput (value) {
      if (this.isMultiple) {
        this.$emit('update:selectedProvinces', this.postProcessSelectedProvinces(value))
      } else {
        this.$emit('update:selectedProvince', this.postProcessSelectedProvince(value))
      }
    }
  }
}
</script>
