<template>
  <div class="other" v-show="true">
    <!-- messagesAvailable not feasible: too much resetting working localization -->
    <h1 class="sr-only">{{ $t("comp.other.title") }}</h1>
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('organizations.title')" lazy>
        <Organizations/>
      </b-tab>
      <b-tab :title="$t('provinces.title')" lazy>
        <Provinces/>
      </b-tab>
      <b-tab :title="$t('states.title')" lazy>
        <States/>
      </b-tab>
      <b-tab :title="$t('ranges.title')" lazy>
        <Ranges/>
      </b-tab>
      <b-tab :title="$t('projecttypes.title')" lazy>
        <ProjectTypes/>
      </b-tab>
      <b-tab :title="$t('roles.title')" lazy>
        <Roles/>
      </b-tab>
      <b-tab :title="$t('localization.title')" lazy>
        <Localization/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Localization from '@/components/admin/other/Localization.vue'
import Provinces from '@/components/admin/other/Provinces.vue'
import ProjectTypes from '@/components/admin/other/ProjectTypes.vue'
import Roles from '@/components/admin/other/Roles.vue'
import States from '@/components/admin/other/States.vue'
import Ranges from '@/components/admin/other/Ranges'
import Organizations from '@/components/admin/other/Organizations'

export default {
  name: 'Other',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Roles,
    States,
    Ranges,
    ProjectTypes,
    Localization,
    Organizations,
    Provinces
  },
  computed: {
    pageTitle () {
      if (this.messagesAvailable) {
        return `${this.$i18n.t('other.title')} | ${this.$i18n.t('app.title')}`
      }
      return null
    }
  }
}
</script>
