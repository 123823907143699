import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'nhrDomainCategories'

const VIEW_ADMIN = 'admin'

class NhrDomainCategoryService {
  constructor (view) {
    this.view = view
  }

  select (params = {}) {
    params.view = this.view
    params.type = 'select'
    return DefaultApiService.list(ENDPOINT, params)
  }
}

export default NhrDomainCategoryService
export const nhrDomainCategoryServiceForAdminView = new NhrDomainCategoryService(VIEW_ADMIN)
