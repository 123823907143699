<template>
  <div class="nhrDomainCategorySelect" v-show="messagesAvailable">
    <multiselect v-model="selected" :options="options" @input="onInput" track-by="key" label="label"
                 :multiple="false" :close-on-select="true" :internal-search="true"
                 :clear-on-select="false" :preserve-search="false" :preselect-first="false"
                 :placeholder="placeholder" :selectLabel="$t('select.selectLabel')"
                 :selectedLabel="$t('select.selectedLabel')" :deselectLabel="$t('select.deselectLabel')"
                 :blockKeys="['Delete']" :disabled="isDisabled">
    </multiselect>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { nhrDomainCategoryServiceForAdminView } from '@/services/nhrDomainCategory.service'
import Multiselect from 'vue-multiselect'

export default {
  name: 'NhrDomainCategorySelect',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Multiselect
  },
  props: {
    selectedNhrDomainCategory: {
      type: Object,
      default: null
    },
    view: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rawOptions: [],
      options: [],
      selected: null
    }
  },
  beforeMount () {
    this.loadNhrDomainCategoryOptions()
    if (this.selectedNhrDomainCategory) {
      this.processSelected()
    }
  },
  computed: {
    placeholder () {
      return this.$i18n.t('comp.nhrdomaincategoryselect.nhrdomaincategory.placeholder')
    }
  },
  watch: {
    selectedNhrDomainCategory: function () {
      this.preProcessSelectedNhrDomainCategory()
    },
    '$i18n.locale': function () {
      this.processOptions()
      this.processSelected()
    }
  },
  methods: {
    loadNhrDomainCategoryOptions () {
      if (this.view === 'admin') {
        nhrDomainCategoryServiceForAdminView.select().then((nhrDomainCategories) => {
          this.rawOptions = nhrDomainCategories
        }).then(() => {
          this.processOptions()
        }).catch(error => {
          console.error(error)
        })
      }
    },
    processOptions () {
      this.options = []
      _.each(this.rawOptions, (rawOption) => {
        this.options.push({ key: rawOption.key, label: this.generateLabel(rawOption) })
      })
      this.options.sort((a, b) => String(a.label).localeCompare(String(b.label)))
    },
    processSelected () {
      if (this.selectedNhrDomainCategory) {
        this.selected = { key: this.selectedNhrDomainCategory.key, label: this.generateLabel(this.selectedNhrDomainCategory) }
      }
    },
    generateLabel (nhrDomainCategory) {
      if (this.$i18n.locale === 'en') {
        return nhrDomainCategory.label.en
      } else if (this.$i18n.locale === 'de') {
        return nhrDomainCategory.label.de
      } else { // Fallback
        return nhrDomainCategory.label.en
      }
    },
    preProcessSelectedNhrDomainCategory () {
      this.selected = this.selectedNhrDomainCategory
        ? { key: this.selectedNhrDomainCategory.key, label: this.generateLabel(this.selectedNhrDomainCategory) }
        : null
    },
    postProcessSelectedNhrDomainCategory (selectedOption) {
      return selectedOption ? this.rawOptions.find((rawOption) => rawOption.key === selectedOption.key) : null // Use object with both locales for save
    },
    onInput (option) {
      this.$emit('update:selectedNhrDomainCategory', this.postProcessSelectedNhrDomainCategory(option))
    }
  }
}
</script>
