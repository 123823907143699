<template>
  <div class="roleSelect" v-show="messagesAvailable">
    <multiselect v-model="selected" :options="options" @input="onInput" track-by="key" label="label"
                 :multiple="isMultiple" :close-on-select="isCloseOnSelect" :internal-search="true"
                 :clear-on-select="isClearOnSelect" :preserve-search="false" :preselect-first="false"
                 :placeholder="placeholder" :selectLabel="$t('select.selectLabel')"
                 :selectedLabel="$t('select.selectedLabel')" :deselectLabel="$t('select.deselectLabel')"
                 :blockKeys="['Delete']">
    </multiselect>
  </div>

</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import Multiselect from 'vue-multiselect'
import RoleService from '@/services/role.service'

export default {
  name: 'RoleSelect',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Multiselect
  },
  props: {
    selectedRole: {
      type: String,
      default: null
    },
    selectedRoles: {
      type: Array,
      default: null
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isMultiple: false,
      options: [],
      selected: null
    }
  },
  beforeMount () {
    this.setIsMultiple()
    this.loadRoleOptions()
    this.isMultiple ? this.preProcessSelectedRoles() : this.preProcessSelectedRole()
  },
  computed: {
    isCloseOnSelect () {
      return !this.isMultiple
    },
    isClearOnSelect () {
      return this.isMultiple
    },
    placeholder () {
      if (this.isMultiple) {
        return this.$i18n.t('comp.roleselect.roles.placeholder')
      } else {
        return this.$i18n.t('comp.roleselect.role.placeholder')
      }
    }
  },
  watch: {
    selectedRole: function () {
      this.preProcessSelectedRole()
    },
    selectedRoles: function () {
      this.preProcessSelectedRoles()
    }
  },
  methods: {
    setIsMultiple () {
      if (this.selectedRoles) { this.isMultiple = true }
    },
    loadRoleOptions () {
      const roleService = new RoleService(this.view)
      roleService.select().then((roles) => {
        this.options = roles
      }).then(() => {
        this.preProcessOptions()
      }).catch(error => {
        console.error(error)
      })
    },
    generateLabel (key) {
      return this.messagesAvailable ? this.$i18n.t(`roles.${key}.label`) : key
    },
    preProcessOptions () {
      _.each(this.options, (option) => {
        option.label = this.generateLabel(option.key)
      })
      this.options.sort((a, b) => String(a.label).localeCompare(String(b.label)))
    },
    preProcessSelectedRole () {
      this.selected = this.selectedRole
        ? { key: this.selectedRole, label: this.generateLabel(this.selectedRole) }
        : null
    },
    postProcessSelectedRole (selectedRole) {
      return selectedRole && selectedRole.key ? selectedRole.key : ''
    },
    preProcessSelectedRoles () {
      const roles = []
      if (this.selectedRoles) {
        _.each(this.selectedRoles, (role) => {
          roles.push({ key: role, label: this.generateLabel(role) })
        })
      }
      this.selected = roles
    },
    postProcessSelectedRoles (selectedRoles) {
      return selectedRoles ? _.map(selectedRoles, 'key') : []
    },
    onInput (value) {
      if (this.isMultiple) {
        this.$emit('update:selectedRoles', this.postProcessSelectedRoles(value))
      } else {
        this.$emit('update:selectedRole', this.postProcessSelectedRole(value))
      }
    }
  }
}
</script>
