<template>
  <div class="projectTypeSelect" v-show="messagesAvailable">
    <multiselect v-model="selected" :options="options" @input="onInput" track-by="key" label="label"
                 :multiple="isMultiple" :close-on-select="isCloseOnSelect" :internal-search="true"
                 :clear-on-select="isClearOnSelect" :preserve-search="false" :preselect-first="false"
                 :placeholder="placeholder" :selectLabel="$t('select.selectLabel')"
                 :selectedLabel="$t('select.selectedLabel')" :deselectLabel="$t('select.deselectLabel')"
                 :blockKeys="['Delete']">
    </multiselect>
  </div>

</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Multiselect from 'vue-multiselect'
import ProjectTypeService from '@/services/projectType.service'

export default {
  name: 'ProjectTypeSelect',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Multiselect
  },
  props: {
    selectedProjecttype: {
      type: Object,
      default: null
    },
    selectedProjecttypes: {
      type: Array,
      default: null
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isMultiple: false,
      options: [],
      selected: null
    }
  },
  beforeMount () {
    this.setIsMultiple()
    this.loadProjectTypeOptions()
    this.isMultiple ? this.preProcessSelectedProjectTypes() : this.preProcessSelectedProjectType()
  },
  computed: {
    isCloseOnSelect () {
      return !this.isMultiple
    },
    isClearOnSelect () {
      return this.isMultiple
    },
    placeholder () {
      if (this.isMultiple) {
        return this.$i18n.t('comp.projecttypeselect.projecttypes.placeholder')
      } else {
        return this.$i18n.t('comp.projecttypeselect.projecttype.placeholder')
      }
    }
  },
  watch: {
    selectedProjecttype: function () {
      this.preProcessSelectedProjectType()
    },
    selectedProjecttypes: function () {
      this.preProcessSelectedProjectTypes()
    }
  },
  methods: {
    setIsMultiple () {
      if (this.selectedProjecttypes) { this.isMultiple = true }
    },
    loadProjectTypeOptions () {
      const projectTypeService = new ProjectTypeService(this.view)
      projectTypeService.select().then((projectTypes) => {
        this.options = projectTypes // Sorted in backend
      }).catch(error => {
        console.error(error)
      })
    },
    reset () {
      this.options = []
    },
    preProcessSelectedProjectType () {
      this.selected = this.selectedProjecttype ? this.selectedProjecttype : null
    },
    preProcessSelectedProjectTypes () {
      this.selected = this.selectedProjecttypes ? this.selectedProjecttypes : []
    },
    onInput (value) {
      if (this.isMultiple) {
        this.$emit('update:selectedProjecttypes', value)
      } else {
        this.$emit('update:selectedProjecttype', value)
      }
    }
  }
}
</script>
