import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'roles'

const VIEW_ADMIN = 'admin'
const VIEW_MANAGER = 'manager'
const VIEW_USER = 'user'

class RoleService {
  constructor (view) {
    this.view = view
  }

  count (params = {}) {
    params.view = this.view
    params.type = 'metadata'
    return DefaultApiService.count(ENDPOINT, params)
  }

  list (params = {}) {
    params.view = this.view
    params.type = 'table'
    return DefaultApiService.list(ENDPOINT, params)
  }

  select (params = {}) {
    params.view = this.view
    params.type = 'select'
    return DefaultApiService.list(ENDPOINT, params)
  }

  get (id, params = {}) {
    params.view = this.view
    return DefaultApiService.get(ENDPOINT, id, params)
  }

  create (data, params = {}) {
    params.view = this.view
    return DefaultApiService.create(ENDPOINT, data, params)
  }

  update (data, params = {}) {
    params.view = this.view
    return DefaultApiService.update(ENDPOINT, data, params)
  }

  delete (id, params = {}) {
    params.view = this.view
    return DefaultApiService.delete(ENDPOINT, id, params)
  }
}

export default RoleService
export const roleServiceForUserView = new RoleService(VIEW_USER)
export const roleServiceForManagerView = new RoleService(VIEW_MANAGER)
export const roleServiceForAdminView = new RoleService(VIEW_ADMIN)
